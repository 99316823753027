import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 21 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M19.5 1.5H1.495C.67 1.5 0 2.18 0 3.014v17.972C0 21.82.67 22.5 1.495 22.5H19.5c.825 0 1.5-.68 1.5-1.514V3.014A1.51 1.51 0 0 0 19.5 1.5m-13.153 18H3.234V9.478h3.118V19.5zM4.79 8.11a1.805 1.805 0 0 1 0-3.61c.993 0 1.804.81 1.804 1.805 0 .998-.806 1.804-1.804 1.804ZM18.014 19.5h-3.112v-4.875c0-1.162-.024-2.658-1.618-2.658-1.622 0-1.87 1.266-1.87 2.574V19.5H8.302V9.478h2.986v1.369h.042c.417-.788 1.434-1.617 2.948-1.617 3.15 0 3.736 2.076 3.736 4.776z"
    }, null, -1)
  ])))
}
export default { render: render }